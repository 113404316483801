<template>
  <div>
    <p class="font-sansDemi text-base text-white">
      Would you mind answering these questions while you wait?
    </p>
    <p class="font-sansDemi text-base base-font-gray-lighter-150 mt-3">
      While looking for {{ item.originalChannelName }}, we also found this {{ capitalizeFirstLetter(item.platformFound) }} account.
    </p>
    <div class="bg-white rounded-lg px-3.5 py-6 flex mt-4">
      <img :src="item.avatar" class="w-20 h-20 rounded-full">
      <div class="flex flex-wrap items-center">
        <div class="ml-2.5">
          <img :src="require(`../../../assets/images/${item.platformFound}.png`)">
          <p class="w-full font-sans font-bold text-black text-xl leading-none">
            {{ item.accountId }}
          </p>
          <p class="w-full font-sans text-sm base-font-gray-lighter-160 mt-2">
            {{ formatNumberToSmallView(item.followers) }} followers
          </p>
        </div>
      </div>
    </div>
    <p class="font-sansDemi text-base base-font-gray-lighter-150 mt-4">
      Do you think they are the same?
    </p>
    <div class="mt-5">
      <button 
        @click="platform(true)"
        class="bg-white rounded-2xl px-4 py-1.5 font-monoDemi text-sm text-black mr-1.5"
      >
        Yes
      </button>
      <button 
        @click="platform(false)"
        class="bg-white rounded-2xl px-4 py-1.5 font-monoDemi text-sm text-black"
      >
        No
      </button>
    </div>
  </div>
</template>

<script>
  import { formatNumberToSmallView, capitalizeFirstLetter } from '@/helpers/formats'
  export default {
    props: [ 'item' ],
    data(){
      return{
        formatNumberToSmallView: formatNumberToSmallView,
        capitalizeFirstLetter: capitalizeFirstLetter
      }
    },
    methods: {
      platform(arg){
        this.$emit('platform', {
          "channelId": this.item.originalChannelId,
          "type": "platform",
          "platform": this.item.platformFound,
          "match": arg,
          "accountId": this.item.accountId 
        })
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>