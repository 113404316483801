<template>
  <div class="flex flex-wrap">
    <div class="flex flex-wrap fixed justify-between items-center w-full h-12 border-b border-grey-light-200 flex items-center pl-12 pr-20">
      <breadcrumbs :active="isDataLoaded ? 3 : 2"/>
    </div>

    <!-- We need to change this in the new version -->
<AnalisysStep2New 
  v-if="isShowData"
  :data="loadedData"
  @setRated="setRated"
  :discoveryId="$route.params.hashid" />

    <!-- <AnalisysStep2 
      v-if="isShowData" 
      :data="loadedData" 
      :discoveryId="$route.params.hashid" 
      @setRated="setRated"
    /> -->
    <AnalisysStep1 
      v-else 
      :isDataLoaded="isDataLoaded"
      @viewResults="viewResults"
    />
  </div>
</template>

<script>
  import AnalisysStep1 from '@/components/DiscoveryProcess/AnalisysStep1'
  // import AnalisysStep2 from '@/components/DiscoveryProcess/AnalisysStep2'
  import breadcrumbs from '@/components/breadcrumbs'
  import AnalisysStep2New from '../../components/DiscoveryProcess/AnalisysStep2New.vue'

  export default {
    name: 'DiscoveryAnalisys',
    components:{
      AnalisysStep1,
      // AnalisysStep2,
      AnalisysStep2New,
      breadcrumbs
    },
    data(){
      return {
        loadedData: null,
        isDataLoaded: false,
        interval: null,
        isShowData: false
      }
    },
    methods: {
      viewResults(){
        this.isShowData = true
      },
      async getSubscriptionData() {
        fetch(`https://api.flmntcdn.com/api/v1/discovery/${this.$route.params.hashid}?count=100`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json(); // Parse the response body as JSON
          })
          .then(data => {
            const parsedData = JSON.parse(data.response);
            console.log(parsedData);
            this.loadedData = parsedData;
            this.isDataLoaded = true;
          })
          .catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
          });
      },
      async checkIfLoaded(){
        return await fetch(`https://raditube-discovery-bucket.s3.us-west-1.amazonaws.com/${this.$route.params.hashid}_summary.json`)
      },
      setRated({ id, mode }){
        this.loadedData[mode] = this.loadedData[mode].map(elem => {
          if(elem.id == id) elem.rated = !elem.rated
          return elem
        })
      },
      calcMostSubsPersentage(count){
        return Math.round((count/this.loadedData.meta.openProfiles) * 100)
      },
      calcBestRatioPersentage(count){
        return Number((count * 100).toFixed(1))
      },
    },
    mounted(){

      this.getSubscriptionData();

      // this.interval = setInterval(async () => {
      //   const data =  await this.checkIfLoaded()
      //   const parsedData = await data.json()
      //   if(parsedData){
      //     clearInterval(this.interval)
      //     this.loadedData = JSON.parse(JSON.stringify(parsedData))
      //     const mostSubs = this.loadedData.mostSubs.map(elem => elem.id)
      //     const bestRatio = this.loadedData.bestRatio.map(elem => elem.id)
      //     const under10k = this.loadedData.under10k.map(elem => elem.id)
      //     const mostSubsData = await this.$axios.post('https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get-crowdsource-data', {
      //       channels: mostSubs
      //     })
      //     const bestRatioData = await this.$axios.post('https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get-crowdsource-data', {
      //       channels: bestRatio
      //     })
      //     const under10kData = await this.$axios.post('https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/get-crowdsource-data', {
      //       channels: under10k
      //     })
      //     for (let index = 0; index < mostSubsData.data.length; index++) {
      //       this.loadedData.mostSubs = this.loadedData.mostSubs.map(item => {
      //         if(mostSubsData.data[index].channel_id == item.id) return { ...mostSubsData.data[index], ...item }
      //         else return item
      //       })
      //     }
      //     for (let index = 0; index < bestRatioData.data.length; index++) {
      //       this.loadedData.bestRatio = this.loadedData.bestRatio.map(item => {
      //         if(bestRatioData.data[index].channel_id == item.id) return { ...bestRatioData.data[index], ...item }
      //         else return item
      //       })
      //     }
      //     for (let index = 0; index < under10kData.data.length; index++) {
      //       this.loadedData.under10k = this.loadedData.under10k.map(item => {
      //         if(under10kData.data[index].channel_id == item.id) return { ...under10kData.data[index], ...item }
      //         else return item
      //       })
      //     }
      //     this.loadedData.mostSubs = this.loadedData.mostSubs.map(elem => {
      //       elem.countRatio = this.calcMostSubsPersentage(elem.count)
      //       return elem
      //     })
      //     this.loadedData.bestRatio = this.loadedData.bestRatio.map(elem => {
      //       elem.countRatio = this.calcBestRatioPersentage(elem.ratio)
      //       return elem
      //     })
      //     this.isDataLoaded = true
      //   }else{
      //     console.log('go on!')
      //   }
      // }, 5000)
    },
    beforeDestroy(){
      clearInterval(this.interval)
    }
  }
</script>

<style lang="scss" scoped>
.main-content{
  height: calc(100vh - 6rem);
}
</style>