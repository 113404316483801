<template>
<div class="w-full flex flex-wrap px-12 video-wrapper">
  <div class="flex flex-col justify-between w-1/5 h-full border-r base-border-grey-300"></div>
  <div class="h-full flex flex-wrap items-center justify-center w-2/5 px-4 border-r base-border-grey-300">
    <div class="text-center">
      <p class="w-full font-sansDemi text-base text-white">Analyzing Data</p>
      <p class="w-full font-sans text-base base-font-gray-lighter-100 animate-pulse">This can take a couple minutes</p>
      <button 
        v-if="isDataLoaded"
        @click="viewResults"
        class="flexitems-center h-7 mt-8 font-monoDemi inline-block text-xs px-3 h-7 rounded-2xl whitespace-nowrap text-black bg-white"
      >
        <span>View Results</span>
      </button>
    </div>
  </div>
  <div class="h-full w-2/5 flex items-center justify-center">
    <div class="w-2/3">
      <component 
        v-if="formsToShow.length && activeFormIndex <= (formsToShow.length - 1)"
        :is='`${formsToShow[activeFormIndex].type}Component`'
        :item="formsToShow[activeFormIndex]"
        :key="activeFormIndex"
        @platform="sendPlatform"
        @raiting="sendRaiting"
        @label="sendLabel"
      />
    </div>
  </div>
</div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import platformComponent from './crowdsourcing/platform.vue'
  import ratingComponent from './crowdsourcing/rating.vue'
  import labelComponent from './crowdsourcing/label.vue'

  export default {
    name: 'AnalisysStep1',
    props: {
      isDataLoaded: {
        type: Boolean,
        default: false
      }
    },
    components: {
      platformComponent,
      ratingComponent,
      labelComponent
    },
    data(){
      return {
        formsToShow: [],
        activeFormIndex: 0,
        apiUrl: `${process.env.VUE_APP_BASE_URL}/api/v1/crowdsource`
      }
    },
    methods: {
      viewResults(){
        this.$emit('viewResults')
      },
      sendPlatform(item){
        this.$axios.post(this.apiUrl, item)
        this.activeFormIndex++
      },
      sendRaiting(item){
        this.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/add-crowdsource-data`, item)
        this.activeFormIndex++
      },
      async sendLabel(item){
        const labels =  await this.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/add-crowdsource-data`, item)
        if(labels.data.length) this.formsToShow.splice(this.activeFormIndex + 1, 0, ...labels.data)
        this.activeFormIndex++
      }
    },
    computed: {
      ...mapGetters({
        channelData: 'discovery/channelData',
        analysis_channels: 'discovery/analysis_channels'
      })
    },
    async mounted() {
      if (this.analysis_channels.length === 0) { return null }
      const { data } = await this.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/crowdsource`, this.analysis_channels)
      this.formsToShow = data.forms
    },
  }
</script>

<style lang="scss" scoped>
.video-wrapper{
  height: calc(100vh - 96px);
}
</style>